import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row } from "reactstrap"

const Terms = () => (
  <Layout>
   {/* <Seo title='Terminos y condiciones'/> */}
    <Row className="px-5 mt-5">
        <Col xs='12' className="px-5 mt-5">
            <p className="fs-2 c-wedgewood text-center">
                Aviso de privacidad</p>
            <p className="fs-6 c-wedgewood">
                La protección de la privacidad y el uso legal de los datos personales son una cuestión de prioritaria para Kenko TECH (Kenko), por ello, nos comprometemos a garantizar su privacidad. Este aviso de privacidad , explica cómo recopilamos, usamos, almacenamos y divulgamos sus datos personales: este tratamiento de datos aplica a nuestro sitio web y plataformaa los cuales llamaremos "Servicios"

                Le recomendamos que lea atentamente el Aviso de Privacidad antes de utilizar cualquiera de los Servicios.

                Kenko puede modificar el Aviso de Privacidad de vez en cuando, en particular si, debido a cambios en nuestras operaciones o en las leyes y regulaciones aplicables, sus derechos o libertades fundamentales pudieran sufrir modificaciones.
                </p>
                <p className="fs-6 c-wedgewood">
                Kenko Tech S.A. de C.V. "Kenko", con domicilio ubicado en 13-L 699 Gran Santa Fe, Mérida Yucatán México, CP 97314, desde donde operamos y mantenemos el "Sitio Web" https://www.kenko.mx/ y la "Plataforma" https://kenko.health.

                Para los efectos de las leyes de protección de datos de los Estados Unidos Mexicanos, es decir; de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP) sobre la protección de las personas físicas en lo que respecta al tratamiento de datos personales y sobre la libre circulación de dichos datos Kenko es el responsables del tratamiento de sus datos personales.

                Puede ponerse en contacto con nuestro responsable de protección de datos enviando un correo electrónico a support@kenko.mx o por correo postal, enviándonos su consulta al domicilio mencionado anteriormente.
                </p>
                <p className="fs-6 c-wedgewood">


                Finalidad de la obtención de sus datos personales y tratamiento legal que se les brinda:<br/>

                A) Si se registra en algun formulario comercial dentro de nuestras paginas web, chatbots o redes sociales.<br/>
                Usted como usuario nos proporciona sus datos básicos durante el registro y/o contacto a tarvés de éstos canales, lo cuales serán utlizados para los siguientes fines:<br/>
                    i)  Confirmar su identidad<br/>
                    ii) Brindarle información comercial referente a los servicios que Kenko ofrece.<br/>
                    iii) Enviar promociones<br/>
                    iv) Informar sobre cambios en nuestro servicios<br/>
                    v)  Brindar atención y soporte<br/>

                    </p>
                <p className="fs-6 c-wedgewood">

                A) Usuario registrado como Profesional de la Salud en la plataforma

                Usted como usuario nos proporciona sus datos básicos durante el registro en formularios de redes sociales o sitios de marketing digital. Una vez se registra en la plataforma Kenko es recabada información referente al perfil profesional, sociodemográfica e información básica como paciente ya que todo usuario registrado en la plataforma Kenko cuenta con un perfil personal de paciente. Ésta información es almacenada para permitirle usar el servicio a traves de nuestra página web o plataforma digital.
                </p>
                <p className="fs-6 c-wedgewood">

                A) Usuario registrado como Paciente en la plataforma

                Usted como usuario nos proporciona sus datos básicos durante el registro en formularios de redes sociales, sitios de marketing digital o al momento de agendar una cita con un Profesional de Salud en los links de agendamiento express, en los cuales se le solicita información básica como paciente, tales como nombre, apellidos, fecha de naciemiento, relación con sus dependientes en caso de agendar para uno de ellos.
                Al registrarse como usuario paciente de la plataforma Kenko usted tiene el derecho de registrar la información referente a su expediente medico básico tal como información sociodemográfica, signos vitales, antecedentes médicos propios y familiaresm alergias, vacunas, discapacidades, tratamientos médicos actuales así como subir archivos e imagenes referentes a diagnosticos médicos, estidios, laboratorios u otros referentes al cuidado de su salud o la de sus dependientes. La información capturada tanto por el paciente como por el profesional de la salud es propiedad tanto del paciente como de la cuenta propietaria de la suscripción que realiza la captura, es decir el médico o clínica y los profesionales de la salud que laboren en ella. Usted como paciente autoriza que todo profesional de la salud o centro de salud con una cita agendada para usted tenga acceso a la información básica de sus expediente médico y en lo sucesivo la información capturada por dicho Profesional en Salud o Centro Médico. 

                Derechos sobre el tratamiento de mis datos

                Bajo la LFPDPPP tiene los siguientes derechos:
                Derecho a ser informado sobre el tratamiento de sus datos personales (es decir, para qué fines, qué tipo de datos personales, a qué destinatarios se divulgan, períodos de almacenamiento, cualquier fuente de terceros a partir de la cual se obtuvo, constatación de decisiones automatizadas, incluida la elaboración de perfiles, y la lógica, la importancia y las consecuencias previstas). La consulta de esta Política de Privacidad forma parte de su derecho a estar informado.
                Derecho a presentar una queja ante nosotros support@kenko.mx o ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI), https://home.inai.org.mx/, si considera que tratamos sus datos personales de manera ilegal.
                Derecho a solicitar una copia de los datos personales que tratamos sobre usted.
                Puede pedirnos que borremos (si considera que no tenemos derecho a retenerlo) o rectifiquemos (si cree que son inexactos) sus datos personales.
                Puede oponerse al tratamiento de sus datos personales o (cuando dependamos de su consentimiento para el tratamiento) retirar su consentimiento.
                Derecho a restringir el tratamiento de sus datos personales.
                Puede solicitar que sus datos se proporcionen de forma portátil.
                Puede ponerse en contacto con nosotros si desea hacer valer alguno de estos derechos. Cumpliremos con nuestras obligaciones legales en lo que respecta a sus derechos como interesado.

                Cualquier solicitud de acceso a sus datos personales debe hacerse por escrito y le contestaremos en un periodo no mayor a  15 días.

                Nos reservamos el derecho de negarnos a responder cuando las solicitudes sean manifiestamente infundadas o excesivas: en este caso, le explicaremos la situación y le informaremos sobre sus derechos.

                Nuestro objetivo es garantizar que la información que tenemos sobre usted sea precisa en todo momento.

                Para ayudarnos a mantener su información actualizada, deberá indicarnos cualquier cambio en sus datos personales. Si lo solicita, tomaremos medidas razonables para garantizar que los datos sean precisos y rectificaremos cualquier dato personal incorrecto de inmediato o en un plazo no mayor a  15 días.

                Tiempo de Conservación de Datos Personales

                Mientras el usuario mantenga una cuenta activa dentro de la plataforma, Kenko mantendrá su información para permitir brindarle el servicio adecuado. En caso de cancelación o inactividad de la cuenta, Kenko mantendra la información referente a datos personales por un periodo de 6 años desde su cancelación o inactividad.
                </p>
                <p className="fs-6 c-wedgewood">

                *La manera en que protegemos su información:*

                Nos aseguramos de que existan las medidas de seguridad técnicas, físicas, electrónicas y administrativas adecuadas para proteger sus datos personales del acceso no autorizado.

                Seguimos estándares aceptados por la industria para proteger la información personal que se nos envía, tanto durante la transmisión como después de su recepción.

                Desafortunadamente, la transmisión de información a través de Internet (incluido el correo electrónico) no es completamente segura. Aunque haremos todo lo posible para proteger sus datos personales, no podemos garantizar la seguridad de sus datos transmitidos a nuestro sitio web, plataforma o personal; el usuario asume el riesgo derivado de la transmisión.

                Una vez que hayamos recibido su información, utilizaremos procedimientos estrictos y funciones de seguridad para tratar de evitar el acceso no autorizado.
                </p>
                <p className="fs-6 c-wedgewood">

                *Contactanos*

                En caso de tener cualquier duda respecto al presente aviso de privacidad, puede hacernosla saber al correo support@kenko.mx para que el responsable de privacidad de la información le brinde seguimiento. El presente aviso de provacidad se encuentra disponible para su consulta en el sitio web https://www.kenko.mx, https://www.kenko.health y sus subdominio.
                </p>
        </Col>
    </Row>
  </Layout>
)

export default Terms
